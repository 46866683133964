<template>
  <main class="site-template-v2" :class="`default-font-${default_font}`">
    <template v-if="!openedPanels">
      <slot v-if="highlight && !openedNotifications"></slot>
      <b-container v-if="!highlight && !openedNotifications" fluid>
        <section class="content">
          <div class="container-navbar">
            <Navbar :isMobile="isMobile" />
          </div>
          <slot></slot>
          <FooterBase type="home" />
        </section>
      </b-container>
      <section class="notifications-modal" v-if="openedNotifications">
        <header class="notifications-modal-header">
          <span
            class="back-arrow"
            @click="openedNotifications = !openedNotifications"
          >
            <img src="@/assets/icons/arrow-navigate.svg" alt="arrow icon" />
          </span>
          <section class="title">
            <h5>{{ $t("notification.notifications") }}</h5>
            <section class="count">
              {{ notifications.length || 0 }}
            </section>
          </section>
        </header>
        <section class="notifications-modal-content">
          <template v-if="notifications.length">
            <section
              class="grid-notification"
              v-for="(notification, index) in reverseNotifications"
              :key="index"
              @click="clicked(notification)"
            >
              <section
                class="a"
                :class="{
                  'no-has-picture': !notification.comment.member.picture,
                  'not-viewed': !notification.viewed,
                }"
              >
                <img
                  v-if="notification.comment.member.picture"
                  :src="notification.comment.member.picture"
                  :alt="`profile image of ${notification.comment.member.name}`"
                />
                <p v-else>
                  {{ notification.comment.member.name.substr(0, 1) }}
                </p>
              </section>
              <section class="b">
                <p>
                  <strong>{{ notification.comment.member.name }} </strong>
                  {{ $t("notification.comment") }}
                  <strong>{{ notification.course_title }}</strong>
                </p>
              </section>
              <section class="c">
                {{ formatDate(notification.updated_at) }} |
                {{ notification.lesson_title }}
              </section>
              <section class="d">
                <p>
                  {{ notification.comment.comment }}
                </p>
              </section>
            </section>
          </template>
          <p v-else class="no-notifications">{{ $t("notification.no_notifications_v2") }}</p>
        </section>
      </section>
      <BottomNavigation :isMobile="isMobile" />
    </template>
    <panels-modal-v2 @opened="openedPanels = !openedPanels" />
    <buy-course-modal />
    <modal-novo-painel></modal-novo-painel>
    <Menu v-show="false" />
  </main>
</template>

<script>
// import { EventBus } from "@/main.js";
// Components
import FooterBase from '@/components/FooterBase.vue';
import Navbar from '@/components/V2/navbar/index.vue';
import BottomNavigation from '@/components/V2/bottom-navigation/index.vue';
import PanelsModalV2 from '@/components/V2/modals/PanelsModal';
import BuyCourseModal from '../components/V2/modals/BuyCourseModal.vue';
import ModalNovoPainel from "@/components/ModalNovoPainel";
import { EventBus } from "@/main.js";
import { mapActions } from "vuex";
import io from "socket.io-client";

import Cookies from 'js-cookie';
// Services
import MetaService from '@/services/resources/MetaService';
import NotifyService from '@/services/resources/NotifyService';
import notify from "@/services/libs/notificacao";

import moment from 'moment';
import 'moment/locale/pt-br';
const serviceNotify = NotifyService.build();
const serviceMeta = MetaService.build();
var socketAdd = ""
// if (process.env.VUE_APP_TYPE === "DEV" || Cookies.get("access_level") !== 'student') {
if (Cookies.get("access_level") !== 'student') {
  socketAdd = ""
} else {
  socketAdd = io(`${process.env.VUE_APP_API_SOCKET_HOST}`, {
    extraHeaders: {
      Authorization: Cookies.get("auth_greennCourse")
    }
  })
}

export default {
  name: 'SiteTemplateV2',
  props: ['highlight'],
  components: {
    Navbar,
    BottomNavigation,
    PanelsModalV2,
    BuyCourseModal,
    ModalNovoPainel,
    FooterBase
  },
  data() {
    return {
      openedPanels: false,
      openedNotifications: false,
      showMenuInMobile: false,
      notifications: [],
      luzApagada: false,
      client: {
        width: 0,
      },
      default_font: "",
      socket: socketAdd,
      usernameAlreadySelected: false
    };
  },
  mounted() {
    EventBus.$on('reloadHome', () => {
      setTimeout(() => {
        this.getNotifications();
        this.getClubVersion();
        this.getThemeFixed();
        this.getThemeFixedColor(true);
        this.getColor();
      }, 2000);
    });
    this.getThemeFixed();
    this.getClubVersion();
    this.getColor();
    this.getFont();
    this.$root.$on('changedmaincolor', (data) => {
      this.getColor();
    });

    document.body.style.backgroundImage = '';

    this.$root.$on('luzOn', () => {
      this.luzApagada = true;
    });
    this.$root.$on('luzOff', () => {
      this.luzApagada = false;
    });
    this.$root.$on(
      'show-mobile-notifications',
      () => (this.openedNotifications = !this.openedNotifications),
    );
    // if (process.env.VUE_APP_TYPE !== "DEV" && Cookies.get("access_level") === 'student') {
    if (Cookies.get("access_level") === 'student') {
      this.socket.onAny(() => {})
      this.socket.on("session", ({ sessionID, userID }) => {
        this.socket.auth = { sessionID }
        localStorage.setItem("sessionID", sessionID)
        this.socket.userID = userID
      })
      this.socket.on("LOGOUT", () => {
        this.logoutUser()
      })
      this.socket.on("ADDED_ROOM", () => {})
      this.socket.on("UPDATE_USERS", () => {})
      this.addRoomInSocket();
    }
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    themeLocal() {
      return this.$store.state.globalSettings.theme;
    },
    reverseNotifications() {
      const aux = this.notifications;
      return aux;
    },
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    },
    currentMember() {
      return this.$store.getters.currentMember;
    },
  },
  methods: {
    ...mapActions(["fetchMetas"]),
    handleResize() {
      if (
        (window.matchMedia('(orientation: landscape)').matches &&
          window.outerWidth < 1000) ||
        (window.matchMedia('(orientation: portrait)').matches &&
          window.outerWidth < 769 &&
          window.outerHeight < 1025)
      ) {
        this.client.width = 0;
      } else {
        this.client.width = window.outerWidth;
      }
    },
    getColor() {
      serviceMeta
        .search('keys[]=main_color')
        .then((resp) => {
          this.color = resp.main_color;
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute('data-color', this.color);
            document.documentElement.style.setProperty(
              '--maincolor',
              this.color,
            );
            document.documentElement.style.setProperty(
              '--maincolorn',
              this.$func.convertColor(this.color, 210),
            );
            document.documentElement.style.setProperty(
              '--maincolortrans',
              this.color + '0f',
            );
          } else {
            const d = document;
            d.documentElement.setAttribute('data-color', '#00E4A0');
            document.documentElement.style.setProperty(
              '--maincolor',
              '#00E4A0',
            );
            document.documentElement.style.setProperty(
              '--maincolorn',
              this.$func.convertColor('#00E4A0', 210),
            );
            document.documentElement.style.setProperty(
              '--maincolortrans',
              '#00E4A0' + '0f',
            );

          }
          this.insertTheme();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    async getFont() {
      this.$store.commit('REMOVE_META', 'download_style_file');
      await this.fetchMetas([
        "default_font",
        "download_style_file"
      ]);
    },
    insertTheme() {
      const d = document;
      let theme = Cookies.get('themeClub') ? Cookies.get('themeClub') : 'light';
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute('data-theme', 'light');
        if (document.getElementById("slider")) {
          document.getElementById("slider").checked = false;
        }
      } else {
        d.documentElement.setAttribute('data-theme', theme);
        if (theme === "light") {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = false;
          }
        } else {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = true;
          }
        }
      }
      this.$root.$emit('loadTheme');
      this.$root.$emit('loadsettheme');
    },
    getThemeFixed() {
      serviceMeta
        .search('keys[]=theme_fixed_painel')
        .then((resp) => {
          if (resp.theme_fixed_painel === 'on') {
            this.getThemeFixedColor();
          } else {
            this.insertTheme();
          }
        })
        .catch((err) => console.error(err));
    },
    getThemeFixedColor(fromHomeReload) {
      serviceMeta
        .search('keys[]=theme_fixed_painel_color')
        .then((resp) => {
          let theme = 'light';
          if (resp.theme_fixed_painel_color === 'light') {
            Cookies.set('themeClub', 'light', { expires: 365 });
            theme = 'light';
          } else {
            Cookies.set('themeClub', 'dark', { expires: 365 });
            theme = 'dark';
          }

          if (theme != this.themeLocal && !fromHomeReload) {
            this.$router.go('/home');
          }

          if (fromHomeReload) {
            this.changeTheme(theme);
          }

          document.documentElement.setAttribute('data-theme', theme);
          this.$root.$emit('loadTheme');
          this.$root.$emit('loadsettheme');
        })
        .catch((err) => console.error(err));
    },
    changeTheme(theme) {
      Cookies.set('themeClub', theme, { expires: 365 });
      this.$store.commit('ClubTheme', theme);
      setTimeout(() => {
        // this.insertTheme();
        this.getColor();
      }, 300);
    },
    getClubVersion() {
      serviceMeta
        .search('keys[]=club_version')
        .then((resp) => {
          if (resp.club_version === 'v2') {
            Cookies.set('clubVersion', 'v2', { expires: 365 });
          } else {
            Cookies.set('clubVersion', 'v1', { expires: 365 });
          }
        })
        .finally(() => {})
        .catch((err) => console.error(err));
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }

      return 'Desktop';
    },
    async getNotifications() {
      await serviceNotify
        .search()
        .then((resp) => {
          this.notifications = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async clicked(notification) {
      await serviceNotify
        .postID({ id: notification.id, viewed: true })
        .then(() => {
          this.getNotifications();
        })
        .finally(() => {
          this.$router.push(
            `/curso/${notification.course_id}/modulo/${notification.module_id}/aula/${notification.lesson_id}?c_id=${notification.lesson_comment_id}`,
          );
        });
    },
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date).fromNow();
    },
    addRoomInSocket() {
      setTimeout(() => {
        const data = JSON;
        const dados = this.currentMember;
        data.id = dados.current.currentMember.id
        data.room = dados.current.currentSite.id
        data.role = dados.current.currentMember.role
        this.socket.emit("ADD_ROOM", data)
      }, 2000);
    },
    logoutUser() {
      var that = this;
      notify("erro", "Excesso de Usuários Conectados, você será Desconectado!");
      setTimeout(() => {
        that.$store.dispatch("logoutRequest").catch(function () {});
        this.socket.disconnect();
      }, 5000);
    },
  },
  created() {
    this.detectOs();
    this.getNotifications();
    this.client.width = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    const sessionID = localStorage.getItem("sessionID")
    if (sessionID) {
      this.usernameAlreadySelected = true
      this.socket.auth = { sessionID }
      this.socket.connect()
    }
  },
  updated() {
    this.client.width = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
.site-template-v2 {
  *,
  & {
    box-sizing: border-box;
  }

  min-height: 100vh;
}

@media screen and (max-width: 768px) {
  .site-template-v2 {
    padding-bottom: 100px;
  }
  .cc-tlyw[data-full-view='true'] .cc-kxkl .cc-nsge {
    margin-bottom: 80px !important;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.menu-mobile {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  background: var(--background-v2);
  flex-direction: column;
  color: var(--text-color);
}
.menu-modal-header {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid var(--contrast-bg);

    &::after {
      content: '';
      width: 35px;
    }

    .back-arrow {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      background: var(--closes-bg);
      border-radius: 50%;
      img {
        rotate: 180deg;
        width: 10px;
      }
    }

    .title {
      position: relative;
      display: flex;
      h5 {
        font-size: 18px;
        font-family: 'Montserrat Alternates';
        font-weight: 600;
        color: var(--text-color);
      }
      .count {
        position: absolute;
        text-align: center;
        color: var(--maincolor);
        background: rgba(1, 202, 166, 0.1);
        border-radius: 2px;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 18px;
        padding: 5px;
        text-align: center;
        border-radius: 3px;
        top: -20px;
        right: -25px;
      }
    }
  }
.notifications-modal {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  background: var(--background);
  display: flex;
  flex-direction: column;
  color: var(--text-color);

  &-header {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid var(--contrast-bg);

    &::after {
      content: '';
      width: 35px;
    }

    .back-arrow {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      background: var(--closes-bg);
      border-radius: 50%;
      img {
        rotate: 180deg;
        width: 10px;
      }
    }

    .title {
      position: relative;
      display: flex;
      h5 {
        font-size: 18px;
        font-family: 'Montserrat Alternates';
        font-weight: 600;
        color: var(--text-color);
      }
      .count {
        position: absolute;
        text-align: center;
        color: var(--maincolor);
        background: rgba(1, 202, 166, 0.1);
        border-radius: 2px;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 18px;
        padding: 5px;
        text-align: center;
        border-radius: 3px;
        top: -20px;
        right: -25px;
      }
    }
  }

  .grid-notification {
    display: grid;
    grid-gap: 5px;
    padding: 25px 15px;
    .a {
      grid-area: a;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      &.no-has-picture {
        p {
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
          color: #ffffff;
          text-decoration: none;
        }
        background: var(--maincolor);
      }

      &.not-viewed::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
        background: var(--maincolor);
        border-radius: 50%;
      }
    }
    .b {
      grid-area: b;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      p {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
      }
    }
    .c {
      grid-area: c;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #9a9a9a;
    }
    .d {
      grid-area: d;
      max-width: 355px;
      background: var(--closes-bg);
      border-radius: 10px;
      padding: 10px;

      display: flex;
      flex-wrap: wrap;

      p {
        margin: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    grid-template-areas:
      'a b b b'
      'c c c c'
      'd d d d';
  }

  .no-notifications {
    text-align: center;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
  }
}
.container-navbar {
  display: flex;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
}

@media screen and (min-width: 768px) {
  .container-navbar {
    display: flex;
    width: 100%;
    padding-left: 56px;
    padding-right: 56px;
  }
}
</style>
