<template>
  <div class="menus">
    <section
      class="notification"
      :class="{ 'has-notification': hasNotifications }"
    >
      <img
        v-if="isMobile"
        src="/notification_icon.svg"
        alt="notification-icon-v2"
        class="notification-icon"
        @click="$root.$emit('show-mobile-notifications')"
      />
      <b-dropdown
        v-else
        size="sm"
        variant="link-dark"
        right
        no-caret
        style="height: 40px;"
        menu-class="custom-dropdown"
      >
        <template #button-content>
          <img
            src="/notification_icon.svg"
            alt="notification-icon-v2"
            class="notification-icon"
          />
        </template>
        <b-dropdown-header>
          <header class="header min-width-notification">
            <h1 class="title">{{ $t('notification.notifications') }}</h1>
            <section class="count">
              {{ notifications.length || 0 }}
            </section>
          </header>
        </b-dropdown-header>
        <b-dropdown-item
          v-show="notifications.length > 0"
          v-for="(notification, index) in reverseNotifications"
          :key="index"
          @click="clicked(notification)"
        >
          <section class="grid-notification">
            <section
              class="a"
              :class="{
                'no-has-picture': !notification.comment.member.picture,
                'not-viewed': !notification.viewed,
              }"
            >
              <img
                v-if="notification.comment.member.picture"
                :src="notification.comment.member.picture"
                :alt="`profile image of ${notification.comment.member.name}`"
              />
              <p v-else>
                {{ notification.comment.member.name.substr(0, 1) }}
              </p>
            </section>
            <section class="b">
              <p>
                <strong>{{ notification.comment.member.name }} </strong>
                {{ $t("notification.comment") }}
                <strong>{{ notification.course_title }}</strong>
              </p>
            </section>
            <section class="c">
              {{ formatDate(notification.updated_at) }} |
              {{ notification.lesson_title }}
            </section>
            <section class="d">
              <p>
                {{ notification.comment.comment }}
              </p>
            </section>
          </section>
        </b-dropdown-item>
        <div class="no-notification-container" v-if="notifications.length === 0">
          <span class="no-notification-message">No momento não há nenhuma notificação.</span>
        </div>
      </b-dropdown>
    </section>

    <section class="menu" v-if="!isMobile">
      <b-dropdown
        size="sm"
        variant="link"
        right
        no-caret
        style="height: 40px;"
        menu-class="custom-dropdown-v2 dropdown-menu"
      >
        <template #button-content>
          <img src="/account_icon_v2.svg" alt="account-icon-v2" />
        </template>
        <b-dropdown-item
          v-for="(panel, index) in painelItems"
          :key="index"
          @click="goToPanel(panel)"
          v-show="index < 3"
          class="panel-item-content"
        >
          <section style="padding-left: 5px;" class="panel-items apply-opacity">
            <div v-if="panel.logo" class="panel-logo-container">
              <img
                :src="panel.logo"
                :alt="`Logo do curso ${panel.name}`"
                width="34"
                height="34"
                class="panel-logo"
              />
            </div>
            <div class="icon-panel" v-else>
              <img src="/account_icon_v2.svg" :alt="`panel-${index}`" />
            </div>
            <span class="menu-panel-item-text pt-1">
              {{ panel.name }}
            </span>
            <ExplodeHorizontal />
          </section>
        </b-dropdown-item>
        <b-dropdown-item @click="$root.$emit('show-panel-modal-v2')">
          <section class="panel-items">
            <div class="icon-panel no-background">
              <img src="/panel.svg" alt="panel icon" class="menu-icons" />
            </div>
            {{ $t("menu.all_panels") }}
          </section>
        </b-dropdown-item>
        
        <div class="divider-container">
          <div class="divider-menu"></div>
        </div>

        <!-- <div class="divider-menu"></div> -->
        <!-- <b-dropdown-divider></b-dropdown-divider> -->

        <b-dropdown-item
          v-show="item.show"
          v-for="item in menuItems.filter((item) => item.show)"
          :key="`menu-item-${item.id}`"
          @click="item.action"
        >
          <section class="panel-items">
            <div class="icon-panel no-background">
              <img :src="item.icon" :alt="item.icon" class="menu-icons" />
            </div>
            {{ item.nome }}
          </section>
        </b-dropdown-item>
      </b-dropdown>
    </section>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import ExplodeHorizontal from '../spacer/index.vue';
import moment from 'moment';
import 'moment/locale/pt-br';
// Services
import MemberService from '@/services/resources/MemberService';
import NotifyService from '@/services/resources/NotifyService';
import MetaService from '@/services/resources/MetaService';
const serviceMeta = MetaService.build();
const serviceMember = MemberService.build();
const serviceNotify = NotifyService.build();

export default {
  data() {
    return {
      client: {
        width: 0,
      },
      showMenuItems: false,
      showNotifications: false,
      painelItems: [],
      notifications: [],
      themeFixedPainel: false,
      showHelperMenu: false,
      typeText: '',
      permission2: false
    };
  },
  components: {
    ExplodeHorizontal,
  },
  computed: {
    allSites() {
      return this.$store.getters.allSites;
    },
    currentAuth() { 
      return this.$store.getters.currentAuth;
    },
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    themeIsDark() {
      return this.theme === 'dark';
    },
    themeIsLight() {
      return this.theme === 'light';
    },
    menuItems() {
      return [
        {
          id: 1,
          show: this.themeFixedPainel && this.themeIsDark,
          icon: 'sun.svg',
          nome: this.$t("menu.light_mode"),
          action: () => this.changeTheme('light'),
        },
        {
          id: 2,
          show: this.themeFixedPainel && this.themeIsLight,
          icon: 'sun.svg',
          nome: this.$t("menu.dark_mode"),
          action: () => this.changeTheme('dark'),
        },
        {
          id: 3,
          show: true,
          icon: 'account.svg',
          path: 'minha-conta',
          nome: this.$t("minha_conta.titleV2"),
          action: () => this.changeRoute('/minha-conta'),
        },
        {
          id: 4,
          show: this.showHelperMenu,
          icon: 'help.svg',
          nome: this.$t("helper.help_center"),
          action: () => this.changeRoute('/ajuda'),
        },
        {
          id: 5,
          show: this.permission2,
          icon: 'certificates-mobile.svg',
          nome: `${this.$t("menu.ver_como")} ${this.typeText}`,
          action: () => this.updateTypeView(),
        },
        {
          id: 6,
          show: true,
          icon: 'logout.svg',
          nome: this.$t("menu.logout.logout"),
          action: () => this.logout(),
        },
      ];
    },
    hasNotifications() {
      return !!this.notifications.length;
    },
    reverseNotifications() {
      const items = [...this.notifications];
      return items;
    },
    formatDate() {
      return function (date) {
        moment.locale(this.$i18n.locale);
        return moment(date).fromNow();
      };
    },
  },
  methods: {
    goToPanel(panel) {
      this.$store.dispatch('blogSelected', panel);
    },
    click() {
      setTimeout(() => {
        document.querySelector('.btn-menu').click();
      }, 500);
    },
    changeRoute(path) {
      this.$router.push(path);
    },
    logout() {
      this.$store.dispatch('logoutRequest').catch(function () {});
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changeTheme(t) {
      const theme = !!t ? t : 'light'
      Cookies.set('themeClub', theme, { expires: 365 });
      this.$store.commit('ClubTheme', theme || 'light');
      setTimeout(() => {
        this.insertTheme();
      }, 500);
    },
    insertTheme() {
      const d = document;
      let theme = Cookies.get('themeClub');
      if (theme === undefined || theme === null || theme === 'undefined') {
        d.documentElement.setAttribute('data-theme', 'light');
      } else {
        d.documentElement.setAttribute('data-theme', theme);
      }
      this.$root.$emit('loadTheme');
      this.$root.$emit('loadsettheme');
    },
    getPanels() {
      Cookies.set('memberAuth', this.currentAuth, { expires: 365 });
      this.painelItems = this.allSites;
    },
    toggle(from = 'menu') {
      if (from === 'menu') this.showMenuItems = !this.showMenuItems;
      else this.showNotifications = !this.showNotifications;
    },
    async getNotifications() {
      await serviceNotify
        .search()
        .then((resp) => {
          this.notifications = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async clicked(notification) {
      await serviceNotify
        .postID({ id: notification.id, viewed: true })
        .then(() => {
          this.getNotifications();
        })
        .finally(() => {
          this.$router.push(
            `/curso/${notification.course_id}/modulo/${notification.module_id}/aula/${notification.lesson_id}?c_id=${notification.lesson_comment_id}`,
          );
        });
    },
    getThemeFixed() {
      serviceMeta
        .search('keys[]=theme_fixed_painel')
        .then((resp) => {
          if (resp.theme_fixed_painel == 'on') {
            this.themeFixedPainel = false;
          }
          if (resp.theme_fixed_painel == 'off') {
            this.themeFixedPainel = true;
          }
        });
    },
    async getMetaHelper() {
      await serviceMeta.search('keys[]=helper_data').then((resp) => {
        this.showHelperMenu = resp.helper_data !== 'off';
      });
    },
    getTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        this.typeText = "Aluno";
      } else {
        this.typeText = "Admin";
      }
    },
    updateTypeView() {
      let typeView = Cookies.get("type_student");
      if (typeView === undefined || typeView === "0") {
        Cookies.set("type_student", 1);
      } else {
        Cookies.set("type_student", 0);
      }
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
    getPermission2() {
      let permission2 = Cookies.get("access_level");
      if (permission2 === "admin" || permission2 === "owner") {
        this.permission2 = true;
      } else {
        this.permission2 = false;
      }
    },
   async getJivoChat() {
     await serviceMeta
        .search("keys[]=jivochat")
        .then((resp) => {
          if (
            resp.jivochat !== null &&
            resp.jivochat !== "off"
          ){
            this.getJivoChatId()

          } 
        })
        .catch(err => console.log(err))
    },
   async getJivoChatId() {
     await serviceMeta
        .search("keys[]=id_jivochat")
        .then(resp => {
          if (resp.id_jivochat !== null) {
            const jivoScript = document.createElement("script")
            jivoScript.setAttribute("src", `//code-eu1.jivosite.com/widget/${resp.id_jivochat}`)
            jivoScript.async = true
            document.head.appendChild(jivoScript)
          }
        })
        .catch(err => console.log(err))
    },
    getCrispChat() {
      serviceMeta
        .search("keys[]=crisp")
        .then(resp => {
          if (
            resp.crisp !== null &&
            resp.crisp !== "off"
          ) this.getCrispChatId()
        })
        .catch(err => console.log(err))
    },
    getCrispChatId() {
      serviceMeta
        .search("keys[]=id_crisp")
        .then(resp => {
          if (resp.id_crisp !== null) {
            const crispScript = document.createElement("script")
            crispScript.setAttribute("src", "https://client.crisp.chat/l.js")
            crispScript.async = true
            document.head.appendChild(crispScript)
            window.$crisp = []
            window.CRISP_WEBSITE_ID = resp.id_crisp
          }
        })
        .catch(err => console.log(err))
    }
  },
  created() {
    this.getTypeView();
    this.getPermission2();
    this.getPanels();
    this.getNotifications();
    this.getMetaHelper();
    window.addEventListener(
      'resize',
      () => (this.client.width = window.outerWidth),
    );
    this.handleResize();
    this.getThemeFixed();
  },
 async mounted() {
    this.$root.$on('helper-updated', () => {
      this.getMetaHelper();
    });
    await this.getJivoChat();
    await this.getCrispChat();
  },
};
</script>

<style lang="scss" scoped>
.menus {
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
  gap: 15px;
}
.icon-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  border-radius: 10px;

  &:not(&.no-background) {
    background-color: var(--maincolor); /* Cor de fundo do ícone */
  }
}
.menu {
  /* Propriedades de estilo básicas do ícone */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: var(--maincolor); /* Cor de fundo do ícone */
  border-radius: 10px; /* Bordas arredondadas para criar um ícone circular */
  cursor: pointer;
  z-index: 10;
  /* Transições para suavizar a animação */
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.menu-items-panels-container {
  position: absolute;
  right: 12px;
  border-radius: 20px;
  margin-top: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-width: 257px;
  z-index: 20;
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
}

.menu-panel-item-text {
  white-space: nowrap;
  font-size: 14px !important;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--fontcolor);
}
/* .menu-item {
  min-width: 257px;
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
} */
.panel-item {
  min-width: 257px;
  display: flex;
  cursor: pointer;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
}

.panel-item-content {
  cursor: pointer;
  width: 100%;
  padding-bottom: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 99;
}

.menu-item {
  display: flex;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 28px;
  cursor: pointer;
}

.divider {
  height: 1px;
  display: flex;
  width: 80%;
  margin-top: 6px;
  margin-bottom: 21px;
  margin-right: 10%;
  margin-left: 10%;
  border: 0.2px solid var(--fontcolor2-v2);
}

.min-width-notification {
  min-width: 457px !important;
}

.notification {
  @extend .menu;
  background-color: var(--maincolor);
  position: relative;

  &.has-notification::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--maincolor);
    top: -3px;
    right: -3px;
    border-radius: 50%;
    z-index: 999999999999999999999;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .count {
    text-align: center;
    color: var(--maincolor);
    background: rgba(1, 202, 166, 0.1);
    border-radius: 2px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 18px;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
  }
}

.panel-items {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
}

.menu-icons {
  width: 18px;
  height: 18px;
}

.grid-notification {
  display: grid;
  grid-gap: 5px;
  .a {
    grid-area: a;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    &.no-has-picture {
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
      }
      background: var(--maincolor);
    }

    &.not-viewed::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      background: var(--maincolor);
      border-radius: 50%;
      z-index: 999999999999999999999;
    }
  }
  .b {
    grid-area: b;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    p {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
    }
  }
  .c {
    grid-area: c;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9a9a9a;
  }
  .d {
    grid-area: d;
    max-width: 355px;
    background: var(--closes-bg);
    border-radius: 10px;
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    p {
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  grid-template-areas:
    'a b b b'
    'a c c c'
    'a d d d';
}

.panel-logo-container {
  display: flex;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  max-width: 30px;
}
.panel-logo {
  border-radius: 6px;
  margin-bottom: 15px;
}
.no-notification-container {
  display: flex;
  height: 100px;
  padding-left: 25px;
}
.no-notification-message {
  padding: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 60px;
  display: flex;
  font-family: Montserrat;
  font-size: 15px;
  color: var(--fontcolor2-v2);
  align-items: center;
  border-radius: 12px;
  background: var(--bordercolor-v2);
}
.divider-menu {
  display: flex; 
  width: 100%; 
  min-height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  // margin-left: 10px;
  border-bottom: 1px solid var(--bordercolor-v2);
}
.divider-container {
  display: flex;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
}
</style>
